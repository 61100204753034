import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Halaman Utama' }
  },
  {
    path: '/sejarah',
    name: 'sejarah',
    component: () => import('../views/Profil/SejarahView.vue'),
    meta: { title: 'Sejarah' }
  },
  {
    path: '/visi-misi',
    name: 'visimisi',
    component: () => import('../views/Profil/VisiMisi.vue'),
    meta: { title: 'Visi & Misi' }
  },
  {
    path: '/sarana-prasarana',
    name: 'sapras',
    component: () => import('../views/Profil/SaranaPrasarana.vue'),
    meta: { title: 'Sarana Prasarana' }
  },
  {
    path: '/struktur-organisasi',
    name: 'struktur',
    component: () => import('../views/Profil/StrukturOrganisasi.vue'),
    meta: { title: 'Struktur Organisasi' }
  },
  {
    path: '/profil-guru',
    name: 'profilGuru',
    component: () => import('../views/Profil/ProfilGuru.vue'),
    meta: { title: 'Guru & Staff' }
  },
  {
    path: '/siswa/statistik-siswa',
    name: 'statistikSiswa',
    component: () => import('../views/Siswa/StatistikSiswa.vue'),
    meta: { title: 'Statistik Siswa' }
  },
  {
    path: '/siswa/ekstrakulikuler',
    name: 'ekstrakulikulerView',
    component: () => import('../views/Siswa/EkstrakulikulerView.vue'),
    meta: { title: 'Ekstrakulikuler' }
  },
  {
    path: '/siswa/osis',
    name: 'osisView',
    component: () => import('../views/Siswa/OsisView.vue'),
    meta: { title: 'Osis' }
  },
  {
    path: '/siswa/alumni',
    name: 'alumniView',
    component: () => import('../views/Siswa/AlumniView.vue'),
    meta: { title: 'Alumni' }
  },
  {
    path: '/prestasi/prestasi-siswa',
    name: 'prestasiSiswa',
    component: () => import('../views/Prestasi/PrestasiSiswa.vue'),
    meta: { title: 'Prestasi Siswa' }
  },
  {
    path: '/prestasi/prestasi-gtk',
    name: 'prestasiGtk',
    component: () => import('../views/Prestasi/PrestasiGtk.vue'),
    meta: { title: 'Prestasi GTK' }
  },
  {
    path: '/prestasi/prestasi-madrasah',
    name: 'prestasiMadrasah',
    component: () => import('../views/Prestasi/PrestasiMadrasah.vue'),
    meta: { title: 'Prestasi Madrasah' }
  },
  {
    path: '/artikel',
    name: 'artikel',
    component: () => import('../views/Artikel/ArtikelView.vue'),
    meta: { title: 'Artikel' }
  },
  {
    path: '/artikel/read/:id',
    name: 'artikelDetail',
    component: () => import('../views/Artikel/ArtikelDetail.vue'),
    meta: { title: 'Artikel Detail' }
  },
  {
    path: '/zona-integritas/pengaduan-masyarakat',
    name: 'pengaduanMasyarakat',
    component: () => import('../views/ZonaIntegritas/PengaduanMasyarakat.vue'),
    meta: { title: 'Zona Integritas' }
  },
  {
    path: '/survey-kepuasan',
    name: 'surveyKepuasan',
    component: () => import('../views/ZonaIntegritas/SurveyKepuasan.vue'),
    meta: { title: 'Survey Kepuasan' }
  },
  {
    path: '/ptsp',
    name: 'ptsp',
    component: () => import('../views/PTSP/PtspView.vue'),
    meta: { title: 'PTSP' }
  },
  {
    path: '/ptsp/surat/surat-keterangan-berkelakuan-baik',
    name: 'berkelakuanBaik',
    component: () => import('../views/PTSP/SuratKeteranganBerkelakuanBaik.vue'),
    meta: { title: 'PTSP' }
  },
  {
    path: '/ptsp/surat/surat-keterangan-siswa-aktif',
    name: 'siswaAktif',
    component: () => import('../views/PTSP/SuratKeteranganSiswaAktif.vue'),
    meta: { title: 'PTSP' }
  },
  {
    path: '/ptsp/surat/surat-keterangan-rekomendasi',
    name: 'rekomendasi',
    component: () => import('../views/PTSP/SuratKeteranganRekomendasi.vue'),
    meta: { title: 'PTSP' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const DEFAULT_TITLE = '';

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title || DEFAULT_TITLE) + ' - MAN 10 JAKARTA';
  window.scrollTo(0, 0);
  next();
});

export default router;
