import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
    state: () => ({ isLoading: false }),
    actions: {
        setLoading(value) {
            if (!value) {
                setTimeout(() => {
                    this.isLoading = value;
                }, 1500);
            } else {
                this.isLoading = value;
            }
        },
    },
});