<script setup>
</script>
<template>
    <div class="loading-overlay">
        <!-- <p class="font-black text-white text-2xl mb-7">MAN 10 JAKARTA</p> -->
      <div class="loading-spinner"></div>
  </div>
</template>

<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(5, 150, 105);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #FACC15;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>