<template>
  <NavBar />
  <div class="w-full min-h-screen" style="margin-top: 90px;">
    <router-view />
  </div>
  <FooterFragment />

  <LoadingSpinner v-if="isLoading" />
</template>

<script setup>
import LoadingSpinner from '@/components/Fragments/LoadingSpinner.vue';
import NavBar from '@/components/Fragments/NavBar.vue';
import FooterFragment from '@/components/Fragments/FooterFragment.vue';
import { useMainStore } from '@/stores/mainStore';
// import { watchEffect } from 'vue';
import { computed } from 'vue';

const store = useMainStore();

const isLoading = computed(() => store.isLoading)
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a.router-link-exact-active {
  color: rgb(5 150 105);
}
</style>