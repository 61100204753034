<script setup>
import { ref, reactive, onMounted, computed } from 'vue';
import Instance from '@/plugins/axios';

const kurikulum = ref([{text: 'a', to: '/'}]);
const fetchDataFromServer = () => {
  Instance.get('/home/kurikulum')
    .then(response => {
      kurikulum.value = response.data.data;
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
};

const isMenuOpen = ref(false);
const dropdownOpen = reactive({
  profil: false,
  siswa: false,
});

const closeMenu = () => {
  isMenuOpen.value = false;
};

const toggleDropdown = (dropdown) => {
  Object.keys(dropdownOpen).forEach((key) => {
    if (key !== dropdown) {
      dropdownOpen[key] = false;
    }
  });

  dropdownOpen[dropdown] = !dropdownOpen[dropdown];
};

const navLinks = computed(() => ([
  { text: 'Home', to: '/' },
  { text: 'Profil', children: [
    { text: 'Sejarah', to: '/sejarah' },
    { text: 'Visi & Misi', to: '/visi-misi' },
    { text: 'Sarana & Prasarana', to: '/sarana-prasarana' },
    { text: 'Struktur Organisasi', to: '/struktur-organisasi' },
    { text: 'Guru & Staff', to: '/profil-guru' },
  ]},
  { text: 'Kurikulum', children: kurikulum.value},
  { text: 'Kesiswaan', children: [
    { text: 'Statistik Siswa', to: '/siswa/statistik-siswa' },
    // { text: 'Ekstrakulikuler', to: '/siswa/ekstrakulikuler' },
    // { text: 'Osis', to: '/siswa/osis' },
    { text: 'Alumni', to: '/siswa/alumni' },
  ]},
  { text: 'Prestasi', children: [
    { text: 'Prestasi Siswa', to: '/prestasi/prestasi-siswa' },
    { text: 'Prestasi GTK', to: '/prestasi/prestasi-gtk' },
    { text: 'Prestasi Madrasah', to: '/prestasi/prestasi-madrasah' },
  ]},
  { text: 'Zona Integritas', children: [
    { text: 'Pengaduan Masyarakat', to: '/zona-integritas/pengaduan-masyarakat' },
    { text: 'Survey Kepuasan', to: '/survey-kepuasan' },
  ]},
  { text: 'Artikel', to: '/artikel' },
  { text: 'PTSP', to: '/ptsp' },
]));

const isScrolled = ref(false);

window.addEventListener('scroll', () => {
  isScrolled.value = window.scrollY > 0;
});

onMounted(() => {
  fetchDataFromServer()
});
</script>

<template>
    <div class="w-full bg-white relative z-20 mx-auto navbar" :class="{ 'shadow-lg': isScrolled }">
      <nav class="relative flex flex-wrap items-center justify-between py-6 mx-auto lg:justify-between max-w-screen-xl z-50 bg-white">
        <div class="flex flex-wrap items-center justify-between w-full lg:w-auto">
          <router-link class="flex items-center space-x-2 text-2xl font-medium" to="/">
            <h1 class="font-extrabold text-3xl text-emerald-600">MAN 10 JKT<span class="text-yellow-400">.</span></h1>
          </router-link>
          <button aria-label="Toggle Menu"
            class="px-2 py-1 ml-auto rounded-md lg:hidden hover:text-emerald-600 focus:text-emerald-600 focus:outline-none text-gray-900"
            @click="isMenuOpen = true">
            <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
              </path>
            </svg>
          </button>
        </div>

        <div v-if="isMenuOpen" id="offcanvas" class="fixed inset-0 overflow-hidden z-50">
          <div
            class="absolute inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl transition-transform transform translate-x-0">
            <button @click="closeMenu" type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-7 end-9 flex items-center justify-center border">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span class="sr-only">Close menu</span>
            </button>
            <div class="text-left mt-6 px-10">
              <p class="text-2xl font-black text-emerald-600">MAN 10 JKT<span class="text-yellow-400">.</span></p>
              <ul class="text-base text-gray-900 mt-6 px-1 text-left w-full flex flex-col gap-y-3">
                <li v-for="link in navLinks" :key="link.text" @click="toggleDropdown(link.text.toLowerCase())">
                  <button v-if="link.children" class="w-full font-bold text-lg flex items-center justify-between">
                    <span>{{ link.text }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </button>
                  <router-link v-else :to="link.to" @click="closeMenu" class="font-bold">{{ link.text }}</router-link>

                  <!-- Render child links if available -->
                  <div v-if="link.children && dropdownOpen[link.text.toLowerCase()]" class="ml-4 mt-2">
                    <ul class="flex flex-col gap-y-1">
                      <li v-for="childLink in link.children" :key="childLink.text">
                        <router-link :to="childLink.to" @click="closeMenu">{{ childLink.text }}</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="hidden text-center lg:flex lg:items-center">
          <ul class="items-center justify-end flex-1 pt-6 lg:pt-0 list-reset lg:flex">
            <!-- Iterating through navLinks for desktop links -->
            <li v-for="link in navLinks" :key="link.text" class="relative group">
              <button
                class="flex items-center transition-all rounded-md outline-none focus:outline-none focus-visible:ring-1 focus-visible:text-emerald-600 font-bold text-gray-700 hover:text-emerald-600 inline-block px-4 py-2"
                type="button" aria-haspopup="menu" aria-expanded="false">
                <router-link v-if="link.to" :to="link.to">{{ link.text }}</router-link>
                <span v-else >{{ link.text }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="link.children"
                  class="w-4 h-4 group-hover:rotate-180 duration-150">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
                </svg>
              </button>

              <!-- Render child links if available -->
              <div v-if="link.children" class="z-10 hidden group-hover:block absolute left-4 top-8 mt-2 bg-white shadow-lg rounded-md w-max"
                :id="'menu-dropdown-' + link.text">
                <ul class="text-sm text-gray-900 py-3 px-3 border rounded-md text-left w-full flex flex-col divide-y-2 divide-dashed" :aria-labelledby="'dropdownHoverButton-' + link.text">
                  <li v-for="childLink in link.children" :key="childLink.text" class="dropdown-item">
                    <router-link class="hover:text-emerald-600 block my-1.5 px-2" :to="childLink.to">{{ childLink.text }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <!-- <div class="hidden mx-3 gap-3 lg:flex">
            <a href="https://dashboard.man10jakartabarat.sch.id"
              class="px-5 py-1.5 text-sm text-black bg-yellow-400 font-semibold border-yellow-400 hover:bg-yellow-500 rounded-lg">Login</a>
          </div> -->
        </div>
    </nav>
</div></template>

<style scoped>
.navbar {
  transition: background-color 0.3s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; /* Sesuaikan z-index sesuai kebutuhan */
}

/* .shadow-md {
} */
</style>
