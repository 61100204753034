<script setup>
import { ref, onMounted, computed } from "vue";
import { useMainStore } from '@/stores/mainStore';
import Instance from '@/plugins/axios';
import markdown from '@/plugins/markdown';

const store = useMainStore();
const data = ref([]);
const articles = computed(() => data.value.posts);
const highlightInfo = computed(() => data.value.informasi);

const fetchDataFromServer = () => {
  store.setLoading(false);
  Instance.get('/home')
    .then(response => {
      data.value = response.data.data;
      store.setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
};



onMounted(() => {
  fetchDataFromServer();
});
</script>

<template>
  <div>
    <div class="w-full max-w-screen-xl mx-auto px-5">
      <div class="w-full h-fit border-2 border-yellow-400 rounded-lg flex items-center">
        <div class="h-full bg-yellow-400 text-black font-bold px-4 w-fit rounded-l-lg flex items-center">
          <span>INFO</span>
          <span class="material-symbols-outlined text-lg ml-1">volume_up</span>
        </div>
        <marquee class="text-black-500 font-semibold" onmouseover="this.stop();" onmouseout="this.start();">
          <a  v-for="(item, index) in highlightInfo" :key="index" class="hover:underline cursor-pointer" :href="item.link">
            {{ item.info }} 
            <span v-if="item.link" style="font-size: 17px; margin-top: 4px;" class="material-symbols-outlined">arrow_outward</span>
            <!-- <span class="material-symbols-outlined ml-2">arrow_outward</span> -->
            <span v-if="index !== highlightInfo.length - 1" class="mx-3 text-yellow-400 text-xl">|</span>
          </a>
        </marquee>
      </div>
      <div class="flex flex-col items-center justify-between gap-5 pt-6 lg:flex-row">
        <div class="flex-1 items-center lg:items-start w-full lg:max-w-2xl lg:-mt-6 flex flex-col">
          <h1 class="text-2xl font-bold text-center md:text-3xl lg:text-4xl lg:text-start flex flex-col mb-6 fadein-bot">
            <span class="lg:mb-3">Selamat Datang di Website </span>
            <span>Madrasah Aliyah Negeri 10 Jakarta</span>
          </h1>
          <a href="https://ppdb-madrasahdki.com/"
            class="flex items-center px-4 lg:px-7 py-2 lg:py-3 w-fit text-black text-sm lg:text-xl bg-yellow-400 font-bold hover:bg-yellow-500 rounded-lg fadein-bot">
            <span>PPDB 2024</span>
            <span class="material-symbols-outlined ml-2">arrow_outward</span>
          </a>
        </div>
        <div class="w-full sm:w-[30rem] relative overflow-hidden">
          <div class="absolute w-full h-full -bottom-12 -z-10">
            <img alt="circle shape" src="https://jobhire-next.vercel.app/_next/static/media/circle-shape.a542d5a5.svg"
              decoding="async" data-nimg="fill" loading="lazy"
              style="position: absolute; height: 100%; width: 100%; left: 0; top: 0; right: 0; bottom: 0; color: transparent; " />
          </div>
          <div class="fadein-bot">
            <img alt="Hero Man" sizes="100vw"
            src="../assets/home.png"
              width="984" height="984" class="mt-[-100px]" decoding="async" data-nimg="1" style="color: transparent" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-emerald-600 py-5 lg:py-20">
      <div class="w-full max-w-screen-xl mx-auto px-5">
        <div class="flex flex-col lg:gap-10 lg:flex-row">
          <div class="w-full max-w-sm mx-auto lg:w-1/3 p-10 lg:p-5">
            <div class="relative w-full rounded-full aspect-square bg-gradient-to-b from-yellow-200 to-emerald-300">
              <div class="absolute inset-0 top-5 right-5"><img alt="man" sizes="100vw" src="../assets/kepala_man.png"
                  decoding="async" data-nimg="fill" class="object-cover object-center mt-1 rounded-full" loading="lazy"
                  style="position: absolute; height: 100%; width: 100%; inset: 0px; color: transparent;"></div>
            </div>
            <p class="text-2xl mt-10 text-yellow-400 font-bold">Dr. Nurlaelah M,Pd</p>
          </div>
          <div class="flex-1">
            <h2 class="text-3xl font-bold text-white text-left lg:ml-10 hidden lg:block">KEPALA MAN 10 JAKARTA</h2>
            <div class="text-white text-left lg:p-10">
              <h1 class="font-bold my-5">
                Assalammualaikum Wr. Wb.
              </h1>
              <!-- <br> -->
              <p class="text-justify">
                Alhamdulillahi robbil alamin kami panjatkan kehadlirat Allah SWT karena atas berkat rahmat dan karunia-Nya
                akhirnya Website MAN 10 Jakarta (man10jakartabarat.sch.id) dapat diperbaharui. <br>
                Kami mengucapkan selamat datang di Website Madrasah Aliyah Negeri 10 Jakarta. Website ini sebagai wadah
                untuk dapat mengakses seluruh informasi tentang profil madrasah, kegiatan serta fasilitas madrasah kami.
                <br>
                Saya berharap website ini dapat dijadikan wadah komunikasi yang positif baik antar civitas akademika
                maupun masyarakat pada umumnya sehingga dapat menjalin silaturahmi yang lebih erat disegala unsur. Mari
                kita bekerja dan berkarya dengan mengharap ridho Allah SWT demi Madrasah Hebat Bermartabat.
              </p>
              <!-- <br> -->
              <h1 class="font-bold my-5">
                Waalaikumsalam Wr. Wb.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full max-w-screen-xl mx-auto px-5">
      <div class="my-20">
        <div class="text-center">
          <h2 class="text-3xl font-bold mb-8 lg:mb-0">Artikel Terbaru</h2>
        </div>
        <ul class="grid grid-cols-1 xl:grid-cols-3 gap-y-5 gap-x-5 items-start md:p-8">
            <li
              v-for="(article, index) in articles"
              :key="index"
              class="relative flex flex-col sm:flex-row xl:flex-col items-start text-left border border-2 p-5 rounded-xl"
            >
              <div class="order-1 sm:ml-6 xl:ml-0 w-full">
                <h3 class="mb-1 text-slate-900 font-semibold">
                  <div class="mb-1 block text-sm leading-6 text-emerald-600" >{{ article.date }}</div>
                  <router-link :to="article.link" class="leading-tight">
                    <span class="link link-underline link-underline-black text-black">{{ article.title }}</span>
                  </router-link>
                </h3>
                <div
                  class="prose prose-slate prose-sm text-slate-500 line-clamp-2"
                >
                  <div v-html="markdown.render(article.content)"></div>
                </div>
                <div class="w-full flex justify-end">
                    <router-link
                      class="group inline-flex items-center rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 text-slate-700 mt-6"
                      :to="article.link"
                      >Read more<svg
                        class="overflow-visible ml-3 text-slate-700 group-hover:text-slate-600"
                        width="3"
                        height="6"
                        viewBox="0 0 3 6"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M0 0L3 3L0 6"></path></svg
                    ></router-link>
                </div>
              </div>
              <router-link :to="article.link"
                class="relative w-full aspect-[4/2] mb-6 shadow-md rounded-lg bg-slate-50 overflow-hidden"
              >
                <!-- Badge di atas gambar -->
                
                <img
                alt="Cover"
                sizes="100vw"
                :src="article.image"
                  decoding="async"
                  data-nimg="fill"
                  class="object-cover scale-110 hover:scale-125 transition duration-300"
                  loading="lazy"
                  style="
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    inset: 0px;
                    color: transparent;
                  "
                />
              </router-link>
              <div class="absolute top-7 right-7 bg-yellow-400 text-black py-1 px-2 rounded-lg text-xs font-semibold">
                {{ article.category }}
              </div>
            </li>
          </ul>
        <div class="flex justify-center mt-10">
          <router-link class="inline-flex text-black bg-yellow-400 border border-yellow-400 rounded-full hover:border-black justify-center px-8 py-3"
          to="/artikel">Artikel Lainnya</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fadein-bot {
  opacity: 0;
  animation: fadeInBot 1.5s forwards;
  animation-delay: 1s;
}

@keyframes fadeInBot {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#FACC15, #FACC15)
}

.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%
}
</style>

