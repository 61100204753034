<script setup>
</script>

<template>
    <footer class="pt-16 bg-slate-900 bottom-0">
    <div class="w-full max-w-screen-xl mx-auto px-5">
      <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-9">
        <div class="lg:col-span-2 text-left">
          <router-link class="flex items-center space-x-2 text-2xl font-medium" to="/">
            <h1 class="font-extrabold text-3xl text-emerald-600">MAN 10 JKT<span class="text-yellow-400">.</span></h1>
          </router-link>
          <div class="mt-12">
            <p class="text-white">
              <span>Email : </span><a href="mailto:info@man10jakartabarat.sch.id"
                class="text-emerald-600">info@man10jakartabarat.sch.id</a>
            </p>
            <!-- <p class="mt-2 text-white">
              <span>Telepon : </span><a href="tel:+18521234567" class="text-emerald-600">(021) 5684367</a>
            </p> -->
          </div>
          <div class="mt-6">
            <a href="https://www.facebook.com/pages/MAN-10-Jakarta/106453479390844" class="inline-flex p-2 mr-2 border rounded-full border-white/50 text-white/70"><span
                class="sr-only">Facebook</span><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.58202 15.9403V9.07818H11.8844L12.2299 6.40309H9.58202V4.69543C9.58202 3.92117 9.79614 3.39352 10.9077 3.39352L12.3231 3.39294V1.00024C12.0783 0.968434 11.2381 0.895508 10.2602 0.895508C8.21815 0.895508 6.82014 2.14194 6.82014 4.43049V6.40309H4.51074V9.07818H6.82014V15.9403H9.58202Z"
                  fill="currentColor"></path>
              </svg></a><a href="https://twitter.com/mpkosisman10" class="inline-flex p-2 mr-2 border rounded-full border-white/50 text-white/70"><span
                class="sr-only">Twitter</span><svg width="16" height="16" viewBox="0 0 21 21" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.3183 5.07024C17.7165 5.33101 17.0947 5.51155 16.4327 5.59179C17.1147 5.19059 17.6363 4.54868 17.877 3.76635C17.2351 4.14749 16.533 4.40826 15.7908 4.56874C15.189 3.92683 14.3264 3.52563 13.3836 3.52563C11.5582 3.52563 10.0938 5.01006 10.0938 6.81543C10.0938 7.07621 10.1139 7.31693 10.1741 7.55764C7.44593 7.43729 5.03876 6.11334 3.41391 4.12743C2.25045 6.21364 3.55433 7.93878 4.4169 8.52051C3.89535 8.52051 3.37379 8.36003 2.93248 8.11932C2.93248 9.74416 4.07589 11.0882 5.56031 11.3891C5.23935 11.4894 4.5172 11.5495 4.07589 11.4492C4.49714 12.7531 5.72079 13.716 7.14503 13.736C6.02168 14.6187 4.37678 15.3208 2.27051 15.1001C3.73487 16.0429 5.46001 16.5845 7.32557 16.5845C13.3836 16.5845 16.6734 11.5696 16.6734 7.23669C16.6734 7.09627 16.6734 6.95585 16.6534 6.81543C17.3354 6.31394 17.8971 5.73221 18.3183 5.07024Z"
                  fill="currentColor"></path>
              </svg></a><a href="https://www.instagram.com/man10jakarta_official" class="inline-flex p-2 mr-2 border rounded-full border-white/50 text-white/70"><span
                class="sr-only">Instagram</span><svg width="16" height="16" viewBox="0 0 14 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.5605 0.148926H3.97843C2.16851 0.148926 0.687378 1.62942 0.687378 3.43998V10.0221C0.687378 11.832 2.16851 13.3131 3.97843 13.3131H10.5605C12.3705 13.3131 13.8516 11.832 13.8516 10.0221V3.43998C13.8516 1.62942 12.3705 0.148926 10.5605 0.148926ZM7.26948 9.47347C5.7546 9.47347 4.52689 8.24524 4.52689 6.73103C4.52689 5.21615 5.7546 3.98843 7.26948 3.98843C8.78369 3.98843 10.0121 5.21615 10.0121 6.73103C10.0121 8.24524 8.78369 9.47347 7.26948 9.47347ZM10.8348 3.98843C10.38 3.98843 10.0121 3.61999 10.0121 3.16567C10.0121 2.71135 10.38 2.34291 10.8348 2.34291C11.2897 2.34291 11.6576 2.71135 11.6576 3.16567C11.6576 3.61999 11.2897 3.98843 10.8348 3.98843Z"
                  fill="currentColor"></path>
              </svg></a>
          </div>
        </div>
        <div class="lg:col-span-2 text-left">
          <h4 class="text-xl font-semibold text-white">Link Terkait</h4>
          <ul class="mt-6 text-sm text-white opacity-60">
            <li class="leading-8">
              <a href="https://kemenag.go.id/" class="duration-150 transform hover:text-green-500">Kemenag RI</a>
            </li>
            <li class="leading-8">
              <a href="https://dki.kemenag.go.id/" class="duration-150 transform hover:text-green-500">Kemenag Jakarta</a>
            </li>
            <li class="leading-8">
              <a href="#" class="duration-150 transform hover:text-green-500">Data Pokok Siswa</a>
            </li>
            <li class="leading-8">
              <a href="https://simpatika.kemenag.go.id/madrasah" class="duration-150 transform hover:text-green-500">SIPMATIKA</a>
            </li>
            <!-- <li class="leading-8">
              <a href="#" class="duration-150 transform hover:text-green-500">SIPMA</a>
            </li> -->
          </ul>
        </div>
        <div class="lg:col-span-2 text-left">
          <h4 class="text-xl font-semibold text-white">
            Link Layanan
          </h4>
          <ul class="mt-6 text-sm text-white opacity-60">
            <li class="leading-8">
              <a href="https://ppdb-madrasahdki.com/" class="duration-150 transform hover:text-green-500">PPDB Online</a>
            </li>
            <li class="leading-8">
              <a href="https://pts-pas.e-learningman10jakarta.com/" class="duration-150 transform hover:text-green-500">E-Learning</a>
            </li>
            <li class="leading-8">
              <a href="https://rdm.kemenag.go.id/" class="duration-150 transform hover:text-green-500">RDM</a>
            </li>
          </ul>
        </div>
        <div class="lg:col-span-3 text-right">
          <h4 class="mb-6 text-xl font-semibold text-white">Alamat</h4>
          <p class="max-w-md mb-6 text-white/60">
            Jl. Joglo Baru No.77, RT. 005/006 <br> Joglo, Kec. Kembangan, Kota Jakarta Barat <br> DKI Jakarta 11640
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-between gap-3 py-8 border-t border-gray-800 mt-14 lg:flex-row">
        <p class="text-xs text-white/60">
          2023<!-- -->
          MAN 10 JAKARTA. All Right Reserved. Made by<!-- -->
          <a href="https://bagasrakha.netlify.app/" target="_blank" rel="noopener"> Developer</a>
        </p>
        <div class="flex gap-2 text-xs text-white/60">
          <a href="#">Privacy</a> <span>·</span>
          <a href="#">Terms</a>
        </div>
      </div>
    </div>
  </footer>
</template>