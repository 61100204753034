import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css'
import 'v-calendar/style.css';
import VCalendar from 'v-calendar';
import axios from 'axios';
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import HighchartsVue from 'highcharts-vue'

const pinia = createPinia()
const app = createApp(App);

app.use(router);
app.use(VCalendar);
app.use(VueAxios, axios);
app.use(pinia);
app.use(HighchartsVue);

app.mount('#app');
